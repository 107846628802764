import React from "react";
var __jsx = React.createElement;
import styled from 'styled-components';
import Carousel from "../Carousel/Hero";
var HeroCarouselContainer = styled.div.withConfig({
  displayName: "HeroCarousel__HeroCarouselContainer",
  componentId: "sc-1k1p2an-0"
})(["margin:2.625rem auto;"]);
var HeroCarousel = function HeroCarousel(_ref) {
  var carouselItems = _ref.carouselItems;
  return __jsx(HeroCarouselContainer, null, __jsx(Carousel, {
    heroes: carouselItems
  }));
};
HeroCarousel.defaultProps = {
  carouselItems: []
};
export default HeroCarousel;