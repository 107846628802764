import styled from 'styled-components';
import { layoutSizes } from "../../../constants/css";
export var GridTemplate = styled.div.withConfig({
  displayName: "style__GridTemplate",
  componentId: "sc-e9wvuj-0"
})(["display:flex;align-items:stretch;flex-wrap:wrap;margin:-10px;justify-content:center;> *{flex:0 0 auto;margin:10px;&:nth-child(1){display:none;}", "}@media only screen and (min-width:", "){margin:-10px;justify-content:flex-start;", " > *{flex:0 0 auto;margin:10px !important;", "}}"], function (props) {
  return props.hasHow === false && "\n      &:nth-child(1) {\n        display: block;\n      }\n    ";
}, layoutSizes.desktopWidth, function (props) {
  return props.centered === true && "\n      justify-content: space-between;\n    ";
}, function (props) {
  return props.hasHow === false && "\n        &:nth-child(1) {\n          display: block;\n        }\n      ";
});
export var GridFooter = styled.div.withConfig({
  displayName: "style__GridFooter",
  componentId: "sc-e9wvuj-1"
})(["text-align:right;padding:1.75rem 0 0;"]);
export var CenteredGridFooter = styled(GridFooter).withConfig({
  displayName: "style__CenteredGridFooter",
  componentId: "sc-e9wvuj-2"
})(["transform:translateY(50px);position:absolute;left:calc(50% - 50vw);width:100vw;bottom:0;display:flex;justify-content:center;"]);