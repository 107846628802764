import React from "react";
var __jsx = React.createElement;
import Markdown from 'react-markdown';
import Link from "../_global/MaybeLink";
import isExternalLink from "../../utils/isExternalLink";
import { CalloutSectionContainer, CalloutImage, CopyContainer, CallToActionButton, SingleCalloutContainer } from "./style";
import parseCallout from "./ParseCallout";
var SingleCallout = function SingleCallout(_ref) {
  var anchor = _ref.anchor,
    title = _ref.title,
    merchantCallout = _ref.merchantCallout;
  var _parseCallout = parseCallout(merchantCallout),
    asLink = _parseCallout.asLink,
    calloutImage = _parseCallout.calloutImage,
    callToAction = _parseCallout.callToAction,
    description = _parseCallout.description,
    href = _parseCallout.href;
  var useExternalLink = isExternalLink(href);
  return __jsx(CalloutSectionContainer, {
    id: anchor,
    wideView: true
  }, __jsx(SingleCalloutContainer, null, __jsx("div", null, __jsx(Link, {
    href: href,
    as: asLink,
    useExternalLink: useExternalLink
  }, __jsx(CalloutImage, {
    title: title,
    calloutImage: calloutImage
  }))), __jsx(CopyContainer, {
    callOutCount: 1
  }, title && __jsx("h1", null, title), description && __jsx(Markdown, null, description), __jsx(Link, {
    href: href,
    as: asLink,
    useExternalLink: useExternalLink
  }, __jsx(CallToActionButton, {
    outline: true,
    primary: true
  }, callToAction)))));
};
SingleCallout.defaultProps = {
  anchor: '',
  title: '',
  merchantCallout: {}
};
export default SingleCallout;