import React from "react";
var __jsx = React.createElement;
/** @format */

import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { layoutSizes, colours, fontSizes, zIndexes, responsiveQuery, pixel, mobileHeaderHeight } from "../../constants/css";
import isExternalLink from "../../utils/isExternalLink";
var NavList = styled.ul.withConfig({
  displayName: "NavBar__NavList",
  componentId: "sc-1dzf3jp-0"
})(["position:", ";background:", ";border-bottom:1px solid ", ";z-index:", ";top:0;list-style:none;margin:0;padding:0;white-space:nowrap;.navList-container{padding:0 1rem;overflow-x:auto;}@media only screen and (min-width:", "){.navList-container{padding:0;}text-align:center;}@media only screen and ", "{top:", ";overflow:hidden;.navList-container{padding-bottom:50px;margin-bottom:-50px;}}"], function (props) {
  return props.openChatBox ? 'absolute' : 'sticky';
}, colours.qantasWhite, colours.qantasAquaqantasGrey, zIndexes.campaignNavbar, layoutSizes.desktopWidth, responsiveQuery.mobileNew, pixel(mobileHeaderHeight));
var NavListItem = styled.li.withConfig({
  displayName: "NavBar__NavListItem",
  componentId: "sc-1dzf3jp-1"
})(["display:inline-block;margin-right:2.75rem;&:last-child{margin-right:0;}> a{display:inline-block;padding:1rem 0 0.625rem;color:#000000;text-decoration:none;font-size:", ";border-bottom:4px solid transparent;&:hover{text-shadow:0 0 0.6px black;border-color:", ";}}.active{text-shadow:0 0 0.6px black;border-color:", ";}"], fontSizes.large, colours.qantasRed, colours.qantasRed);
var NavBar = function NavBar(_ref) {
  var navItems = _ref.navItems,
    initialPage = _ref.initialPage;
  var _useState = useState(''),
    currentPage = _useState[0],
    setCurrentPage = _useState[1];
  useEffect(function () {
    setCurrentPage(initialPage);
  }, [initialPage]);
  return __jsx(NavList, null, __jsx("div", {
    className: "navList-container"
  }, navItems.map(function (_ref2, i) {
    var anchor = _ref2.anchor,
      displayName = _ref2.displayName,
      url = _ref2.url;
    if (!url) {
      return __jsx(NavListItem, {
        key: i
      }, __jsx("a", {
        onClick: function onClick() {
          return setCurrentPage(anchor);
        },
        href: "#".concat(anchor),
        className: anchor === currentPage ? 'active' : ''
      }, displayName));
    }
    if (isExternalLink(url)) {
      return __jsx(NavListItem, {
        key: i
      }, __jsx("a", {
        href: url,
        rel: "noreferrer noopener",
        target: "_blank"
      }, displayName));
    }
    return __jsx(NavListItem, {
      key: i
    }, __jsx(Link, {
      href: url
    }, __jsx("a", {
      className: currentPage === url ? 'active' : ''
    }, displayName)));
  })));
};
NavBar.defaultProps = {
  navItems: [],
  initialPage: ''
};
export default NavBar;