var ParseCallout = function ParseCallout(merchantCallout) {
  var merchant = merchantCallout.merchant,
    title = merchantCallout.title,
    description = merchantCallout.description,
    calloutImage = merchantCallout.calloutImage,
    anchor = merchantCallout.anchor,
    callToAction = merchantCallout.callToAction,
    linkUrl = merchantCallout.linkUrl;
  return merchant ? {
    href: "/merchant?slug=".concat(merchant.merchantId.replace(/\+/g, '%2B')),
    callToAction: 'Shop Now',
    asLink: "/".concat(merchant.merchantId),
    title: title,
    calloutImage: calloutImage,
    description: description
  } : {
    anchor: anchor,
    href: linkUrl,
    callToAction: callToAction || 'Shop Now',
    title: title,
    calloutImage: calloutImage,
    description: description
  };
};
export default ParseCallout;