var __jsx = React.createElement;
import React from 'react';
import Link from 'next/link';
var MaybeLink = function MaybeLink(_ref) {
  var useExternalLink = _ref.useExternalLink,
    useNextLink = _ref.useNextLink,
    href = _ref.href,
    as = _ref.as,
    children = _ref.children;
  if (!href) {
    return children;
  }
  if (useExternalLink) {
    return __jsx("a", {
      href: href,
      target: "_blank",
      rel: "noopener noreferrer"
    }, children);
  }
  if (useNextLink) {
    return __jsx(Link, {
      href: href,
      as: as
    }, children);
  }
  return children;
};
MaybeLink.defaultProps = {
  useExternalLink: false,
  useNextLink: true
};
export default MaybeLink;