import styled from 'styled-components';
import { Spinner } from "../../_global";
import InfoBox from "../../InfoBox";
import { customFonts, layoutSizes, fontSizes, gradients, colours } from "../../../constants/css";
export var OfferSection = styled.div.withConfig({
  displayName: "style__OfferSection",
  componentId: "sc-128pgcd-0"
})(["position:relative;background-image:", ";min-height:400px;padding:2.75rem 0 4.5rem;h2{margin:0 0 1.5rem;font-family:", ";font-size:", ";font-weight:normal;}"], function (props) {
  return props.showBg ? gradients.greyToWhite : 'transparent';
}, customFonts.ciutadellaMed, fontSizes.xxxl);
export var NoOffers = styled.div.withConfig({
  displayName: "style__NoOffers",
  componentId: "sc-128pgcd-1"
})(["text-align:center;h3{display:inline-block;width:150px;color:", ";font-family:", ";font-weight:normal;}"], colours.qantasGrey, customFonts.ciutadellaMed);
export var Loading = styled(Spinner).withConfig({
  displayName: "style__Loading",
  componentId: "sc-128pgcd-2"
})(["position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);"]);
export var HowItWorks = styled(InfoBox).withConfig({
  displayName: "style__HowItWorks",
  componentId: "sc-128pgcd-3"
})(["display:", ";margin-bottom:2rem;@media only screen and (min-width:", "){display:", ";}"], function (props) {
  return props.tooltip ? 'block' : 'none';
}, layoutSizes.desktopWidth, function (props) {
  return props.tooltip ? 'none' : 'block';
});