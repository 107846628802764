/** @format */

import styled from 'styled-components';
import { Button, Container } from "../_global";
import { layoutSizes, responsiveQuery } from "../../constants/css";
export var CalloutImage = styled.a.withConfig({
  displayName: "style__CalloutImage",
  componentId: "sc-1ritoj0-0"
})(["display:block;background:url('", "') no-repeat;background-size:contain;background-position:center;text-decoration:none;cursor:pointer;@media only screen and (min-width:", "){background-position:initial;}"], function (props) {
  return props.calloutImage;
}, layoutSizes.desktopWidth);
export var CopyContainer = styled.div.withConfig({
  displayName: "style__CopyContainer",
  componentId: "sc-1ritoj0-1"
})(["vertical-align:top;padding:1rem;h1{font-size:1.625rem;margin-top:0;margin-bottom:1rem;}h2{margin-top:0;}@media only screen and (min-width:", "){padding:1.875rem ", ";}"], layoutSizes.desktopWidth, function (props) {
  return props.callOutCount !== 1 && '0';
});
export var CallToActionButton = styled(Button).withConfig({
  displayName: "style__CallToActionButton",
  componentId: "sc-1ritoj0-2"
})(["margin-top:0.625rem;width:100%;&:focus{outline:none;}@media only screen and (min-width:", "){min-width:165px;}"], layoutSizes.desktopWidth);
export var CalloutSectionContainer = styled(Container).withConfig({
  displayName: "style__CalloutSectionContainer",
  componentId: "sc-1ritoj0-3"
})(["scroll-margin-top:4rem;margin:2.625rem auto;> h2{font-size:1.875rem;margin-top:0;}"]);
export var SingleCalloutContainer = styled.div.withConfig({
  displayName: "style__SingleCalloutContainer",
  componentId: "sc-1ritoj0-4"
})(["display:flex;flex-direction:column;> div:first-child{flex-shrink:0;", "{height:45vw;}}@media only screen and (min-width:", "){flex-direction:row;> div:first-child{flex-basis:400px;}}@media only screen and ", "{> div:first-child{flex-basis:600px;height:300px;", "{height:100%;}}}"], CalloutImage, layoutSizes.mobileWidth, responsiveQuery.tabletAndDesktop, CalloutImage);