var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import Markdown from 'react-markdown';
import { layoutSizes, responsiveQuery } from "../../constants/css";
import Link from "../_global/MaybeLink";
import isExternalLink from "../../utils/isExternalLink";
import { CalloutSectionContainer, CalloutImage, CopyContainer, CallToActionButton } from "./style";
import parseCallout from "./ParseCallout";
var MultipleCalloutContainer = styled.div.withConfig({
  displayName: "MultipleCallout__MultipleCalloutContainer",
  componentId: "sc-qckmq6-0"
})(["display:flex;flex-direction:column;> div{margin:0 0 1.5rem 0;&:last-child{margin-bottom:0;}", "{height:195px;}}@media only screen and (min-width:", "){justify-content:space-between;flex-direction:row;> div{display:flex;flex-direction:column;justify-content:space-between;width:", ";margin-bottom:0;", "{height:", ";}", "{display:flex;flex-direction:column;justify-content:space-between;height:", ";padding-bottom:0;> h2{margin:0;}}&:last-child{padding-right:0;}}}@media only screen and ", "{> div{", "{height:", ";}", "{height:", ";}}}"], CalloutImage, layoutSizes.mobileWidth, function (props) {
  return "calc(".concat(100 / props.calloutCount, "% - 15px)");
}, CalloutImage, function (props) {
  return props.calloutCount === 2 ? '200px' : '135px';
}, CopyContainer, function (props) {
  return props.calloutCount === 2 ? 'calc(100% - 200px)' : 'calc(100% - 135px)';
}, responsiveQuery.tabletAndDesktop, CalloutImage, function (props) {
  return props.calloutCount === 2 ? '310px' : '210px';
}, CopyContainer, function (props) {
  return props.calloutCount === 2 ? 'calc(100% - 310px)' : 'calc(100% - 210px)';
});
var MultipleCallout = function MultipleCallout(_ref) {
  var merchantCallouts = _ref.merchantCallouts,
    sectionTitle = _ref.title,
    anchor = _ref.anchor;
  return __jsx(CalloutSectionContainer, {
    id: anchor,
    wideView: true
  }, sectionTitle && __jsx("h2", null, sectionTitle), __jsx(MultipleCalloutContainer, {
    calloutCount: merchantCallouts.length
  }, merchantCallouts.map(function (merchantCallout, i) {
    var _parseCallout = parseCallout(merchantCallout),
      asLink = _parseCallout.asLink,
      calloutImage = _parseCallout.calloutImage,
      callToAction = _parseCallout.callToAction,
      description = _parseCallout.description,
      title = _parseCallout.title,
      href = _parseCallout.href;
    var useExternalLink = isExternalLink(href);
    return __jsx("div", {
      key: i
    }, __jsx(Link, {
      href: href,
      useExternalLink: useExternalLink,
      as: asLink
    }, __jsx(CalloutImage, {
      title: title,
      calloutImage: calloutImage
    })), __jsx(CopyContainer, null, title && __jsx("h2", null, title), description && __jsx(Markdown, null, description), __jsx(Link, {
      href: href,
      as: asLink,
      useExternalLink: useExternalLink
    }, __jsx(CallToActionButton, {
      outline: true,
      primary: true
    }, callToAction))));
  })));
};
MultipleCallout.defaultProps = {
  anchor: '',
  title: '',
  merchantCallouts: []
};
export default MultipleCallout;