import React from "react";
var __jsx = React.createElement;
import Markdown from 'react-markdown';
import Link from "../_global/MaybeLink";
import isExternalLink from "../../utils/isExternalLink";
import { CalloutSectionContainer, CalloutImage, CopyContainer, CallToActionButton, SingleCalloutContainer } from "./style";
var ExternalCallout = function ExternalCallout(_ref) {
  var anchor = _ref.anchor,
    title = _ref.title,
    callToAction = _ref.callToAction,
    linkUrl = _ref.linkUrl,
    calloutImage = _ref.calloutImage,
    description = _ref.description;
  var useExternalLink = isExternalLink(linkUrl);
  return __jsx(CalloutSectionContainer, {
    id: anchor,
    wideView: true
  }, __jsx(SingleCalloutContainer, null, __jsx("div", null, __jsx(Link, {
    href: linkUrl,
    useExternalLink: useExternalLink
  }, __jsx(CalloutImage, {
    title: title,
    calloutImage: calloutImage
  }))), __jsx(CopyContainer, {
    callOutCount: 1
  }, title && __jsx("h1", null, title), description && __jsx(Markdown, null, description), __jsx(Link, {
    href: linkUrl,
    useExternalLink: useExternalLink
  }, __jsx(CallToActionButton, {
    outline: true,
    primary: true
  }, callToAction)))));
};
ExternalCallout.defaultProps = {
  anchor: '',
  title: '',
  description: '',
  calloutImage: '',
  callToAction: '',
  linkUrl: ''
};
export default ExternalCallout;