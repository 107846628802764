var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { colours, responsiveQuery } from "../../constants/css";
import MediaQuery from 'react-responsive';
var HeroContainer = styled.div.withConfig({
  displayName: "Hero__HeroContainer",
  componentId: "sc-bit2en-0"
})(["position:relative;width:100%;min-height:204px;img{display:block;width:100%;height:auto;}h1{color:", ";font-size:2.2rem;text-shadow:rgba(0,0,0,0.9) 0px 2px 10px;margin:0;text-align:center;position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);width:100%;}"], colours.qantasWhite);
var Hero = function Hero(_ref) {
  var title = _ref.title,
    hero = _ref.hero,
    heroMobile = _ref.heroMobile,
    showTitle = _ref.showTitle;
  return __jsx(HeroContainer, {
    desktopImage: hero,
    mobileImage: heroMobile
  }, __jsx(MediaQuery, {
    query: responsiveQuery.desktop
  }, __jsx("img", {
    src: hero,
    alt: "".concat(title, " banner")
  })), __jsx(MediaQuery, {
    query: responsiveQuery.mobile
  }, __jsx("img", {
    src: heroMobile,
    alt: "".concat(title, " banner")
  })), showTitle && __jsx("h1", null, title));
};
Hero.defaultProps = {
  hero: '',
  heroMobile: '',
  title: '',
  showTitle: true
};
export default Hero;