var __jsx = React.createElement;
import React from 'react';
import Router from 'next/router';
import { HOME_PAGE_SECTIONS } from "../../constants/app";
import EventTrackingHelper from "../../utils/trackingUtilities";
import { HowItWorksMobile, StyledLink } from "./style";
export var heroSlickSettings = {
  infinite: true,
  autoplay: true,
  swipeToSlide: true,
  dots: false,
  arrows: false,
  draggable: true,
  slidesToShow: 1,
  autoplaySpeed: 5000,
  centerMode: true,
  variableWidth: true
};
export var calloutSlickSettings = {
  infinite: false,
  swipeToSlide: true,
  dots: false,
  arrows: false,
  draggable: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [{
    breakpoint: 960,
    settings: {
      slidesToShow: 2.1,
      slidesToScroll: 1
    }
  }, {
    breakpoint: 768,
    settings: {
      slidesToShow: 2.3,
      slidesToScroll: 1
    }
  }, {
    breakpoint: 480,
    settings: {
      slidesToShow: 1.2,
      slidesToScroll: 1
    }
  }]
};
export var getHowItWorks = function getHowItWorks(offerType) {
  var redirect = function redirect(e) {
    e.preventDefault();
    switch (offerType) {
      case HOME_PAGE_SECTIONS.ONLINE_MALL:
        EventTrackingHelper.trackGenericAction('online mall|How it works');
        Router.push('/how-it-works');
        break;
      case HOME_PAGE_SECTIONS.CARD_OFFERS:
        EventTrackingHelper.trackGenericAction('card offers|How it works');
        Router.push('/how-it-works#card-offers');
        break;
      default:
        EventTrackingHelper.trackGenericAction("".concat(offerType, "|How it works"));
        Router.push('/how-it-works');
        break;
    }
  };
  return __jsx(HowItWorksMobile, null, __jsx(StyledLink, {
    onClick: function onClick(e) {
      return redirect(e);
    }
  }, "How it works"));
};