import React from "react";
var __jsx = React.createElement;
import styled from 'styled-components';
import Markdown from 'react-markdown';
import { Container } from "../_global";
import { fontSizes, colours } from "../../constants/css";
var CopyBlockContainer = styled.div.withConfig({
  displayName: "CopyBlock__CopyBlockContainer",
  componentId: "sc-54ujm2-0"
})(["scroll-margin-top:4rem;padding:2.625rem 0;background-image:linear-gradient(to right,", ",", ");h1{font-size:", ";margin:0 0 1rem;}"], colours.qantasWhite, colours.qantasGray91, fontSizes.xxl);
var CopyBlock = function CopyBlock(_ref) {
  var anchor = _ref.anchor,
    title = _ref.title,
    description = _ref.description;
  return __jsx(CopyBlockContainer, {
    id: anchor
  }, __jsx(Container, {
    wideView: true
  }, __jsx("h1", null, title), __jsx(Markdown, null, description)));
};
CopyBlock.defaultProps = {
  anchor: '',
  title: '',
  description: ''
};
export default CopyBlock;